body {
  background-color: #1f1e22;
  font-family: ui-sans-serif;
}

#root {
  justify-content: center;
  align-items: center;
}

.App-desktop {
  text-align: center;
  color: white;
  margin: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.App-mobile {
  display: block;
  text-align: center;
  justify-content: center;
  color: white;
}  

.Profile-desktop {
  height: 180pt;
  margin-top: 70pt;
  pointer-events: none;
  border-radius: 50%;
}

.Profile-mobile {
  height: 180pt;
  border-radius: 50%;
}

.Header-desktop {
  display: flex;
  flex-direction: row;
  margin: auto;
  margin-top: 30pt;
  max-width: 600pt;;
  min-width: 40%;
}

.Header-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Content-desktop {
  margin-top: 70pt;
  margin-bottom: 10pt;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.Content-mobile {
  margin-top: 10pt;
  margin-bottom: 10pt;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.Content-item {
  display: flex;
  flex-direction: column;
  margin: 0;
  font-size: 10pt;
  margin-left: 10pt;
  margin-right: 10pt;
}

.About-desktop {
  text-align: left;
  min-width: 350pt;
  margin: 20pt;
}

.About-mobile {
  text-align: left;
  width: 80%;
}

.Text-desktop {
  font-size: 20pt;
  margin-top: 10pt;
  margin-bottom: 10pt;
}

.Text-mobile {
  margin-top: 10pt;
  margin-bottom: 10pt;
  font-size: 12pt;
}

.Title-desktop {
  margin-top: 10pt;
  margin-bottom: 10pt;
  font-size: 38pt;
  font-weight: bold;
}

.Title-mobile {
  margin-top: 10pt;
  margin-bottom: 10pt;
  font-size: 24pt;
  font-weight: bold;
}

a {
  margin-bottom: 5pt;
  color: white;
  text-decoration: none;
}

.Email-desktop {
  font-size: 24pt;
}

.Email-mobile {
  font-size: 11pt;
}

.Content-desktop img{
  height: 50pt;
}

.Content-mobile img{
  height: 35pt;
}